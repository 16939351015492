import React, { useEffect, useState } from 'react';
import { bool } from 'prop-types';
import AutocompleteFilterPlain from './AutocompleteFilterPlain';
import AutocompleteFilterPopup from './AutocompleteFilterPopup';
import { get } from '../../../util/api';
const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const AutocompleteFilter = props => {
  const { showAsPopup, ...rest } = props;

  const [customBrands, setCustomBrands] = useState([]);
  useEffect(() => {
    get('/api/get-brands-from-db')
      .then(resp => {
        setCustomBrands(resp);
      })
      .catch(e => console.log(e));
  }, []);

  return showAsPopup ? (
    <AutocompleteFilterPopup {...rest} customBrands={customBrands} />
  ) : (
    <AutocompleteFilterPlain {...rest} customBrands={customBrands} />
  );
};

AutocompleteFilter.defaultProps = {
  showAsPopup: false,
};

AutocompleteFilter.propTypes = {
  showAsPopup: bool,
};

export default AutocompleteFilter;
